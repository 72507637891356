import logo from "../AWS_Infrastructure.svg";
import * as React from "react";

/**
 * Header component with approved AWS infra icon.
 */
export const Header = () => {
  return (
    <div className={"awsscm-infra-logo-header"}>
      <img src={logo} alt={"AWS infrastructure logo"}/>
      <p>Supply Chain</p>
    </div>
  )
}