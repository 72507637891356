import { NotificationType, UserState } from "../user/UserState";
import { Alert } from "@amzn/awsui-components-react-v3";
import * as React from "react";


export interface NotificationBoxProps {
  userState: UserState,
  setUserState: (userState: UserState) => void
}

/**
 * React component to display notification message.
 * @param props component props.
 */
export const NotificationBox = (props: NotificationBoxProps) => {
  const messageType = props.userState.message?.type === NotificationType.INFO ? "success" : "error";
  if (props.userState.message === null) {
    return null;
  } else {
    return <Alert
      onDismiss={() => resetMessageStatus({userState: props.userState, setUserState: props.setUserState})}
      visible={true} dismissAriaLabel="Close alert"
      dismissible type={messageType}>
      {props.userState.message?.message}
    </Alert>
  }
}

/**
 * Reset and clean the message content for notification box.
 */
export function resetMessageStatus(props: NotificationBoxProps) {
  if (props.userState.message !== null) {
    props.setUserState({
      authenticated: props.userState.authenticated,
      authState: props.userState.authState,
      user: props.userState.user,
      message: null
    });
  }
}