import { getArgoConfig } from "@awsscm/argo-core/argo/window";
import { AuthConfig } from "@awsscm/argo-core/argo/ArgoApp.types";
// @ts-ignore
import { get as _get } from 'lodash';
import { ArgoAuthenticator } from "./ArgoAuthenticator";
import React from "react";
import { AuthState } from "../user/UserState";

export interface AuthenticationDeciderProps {
  content: React.ReactElement
}

/**
 * Decider class to determine if self-hosted login page should be applied.
 * As of today, new login UI is only expected to be used in commercial regions (PDX).
 */
export const AuthenticationDecider = (props: AuthenticationDeciderProps) => {
  const config = getArgoConfig() || {};
  const authConfig = _get<AuthConfig | undefined>(config, 'argo.cognito', undefined);

  if (authConfig && authConfig.am_useSelfHostedLoginUI === true) {
    return (
      <ArgoAuthenticator
        initialState={{user: null, authState: AuthState.SIGN_IN, authenticated: false, message: null}}
        content={props.content}
      />
    );
  } else {
    return props.content;
  }
}

