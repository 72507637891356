import * as React from "react";
import { AuthState, NotificationType, UserState } from "../user/UserState";
import { PasswordResetState } from "./PasswordResetPanel";
import {
  Alert,
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Popover,
  SpaceBetween,
  StatusIndicator
} from "@amzn/awsui-components-react-v3";
import { Auth } from "@awsscm/awsscm-auth-manager/auth/AuthManager";

import { NotificationBox, resetMessageStatus } from "../notification/NotificationBox";
import "../login/LoginPanel.scss";
import "../AWS_Infrastructure.scss";
import { Header } from "../login/Header";

export interface NewPasswordSubmitPanelProps {
  passwordRestState: PasswordResetState
  setUserState: (userState: UserState) => void,
  userState: UserState
  maxAllowedAttempts: number
}

/**
 * This component helps user to submit auth code and new password to complete the password reset flow.
 *  - If valid auth code and new password are entered, it will complete the flow and navigate to main login page.
 *  - Otherwise, it throws error message in notification box.
 *
 * @param props component props.
 */
export const NewPasswordSubmitPanel = (props: NewPasswordSubmitPanelProps) => {
  const [authCode, setAuthCode] = React.useState<string>("");
  const [newPassword1, setNewPassword1] = React.useState<string>("");
  const [newPassword2, setNewPassword2] = React.useState<string>("");

  const [attempts, setAttempts] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);

  const isResetPasswordButtonDisabled = () => {
    return newPassword1 !== newPassword2 || newPassword2 === "" || authCode === "";
  };

  const submitForgetPasswordRequest = () => {
    setLoading(true);
    if (props.passwordRestState.username != null) {
      Auth.submitPasswordReset(props.passwordRestState.username, authCode, newPassword2).then(() => {
        setLoading(false);
        props.setUserState({
          authenticated: false, authState: AuthState.SIGN_IN, user: null,
          message: {message: "Password is reset successfully", type: NotificationType.INFO}
        })
      }).catch((e: Error) => {
        setLoading(false);
        if (attempts >= props.maxAllowedAttempts) {
          props.setUserState({
            authenticated: false,
            authState: AuthState.SIGN_IN,
            user: props.userState.user,
            message: {
              message: "Too many failed attempts.",
              type: NotificationType.ERROR
            }
          });
        } else {
          setAttempts(attempts + 1)
          const remainingAttempts = props.maxAllowedAttempts - attempts;
          props.setUserState({
            authenticated: false,
            authState: AuthState.PASSWORD_RESET,
            user: props.userState.user,
            message: {
              message: "Reset password failed with error: "+ e.message + " Remaining attempts: " + remainingAttempts,
              type: NotificationType.ERROR
            }
          });
        }
      })
    }
  };

  const specialCharactersPopover = () => {
    return (<Popover
      dismissButton={false}
      position="top"
      size="medium"
      triggerType={"text"}
      content={
        <StatusIndicator type="info">
          {'Special characters include: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'}
        </StatusIndicator>
      }>
      a special character
    </Popover>);
  };

  return (
    <div className={"awsscm-argo-password-reset-panel"}>
      <Modal
        size={"small"}
        // close username validation panel will navigate back to main login page
        onDismiss={(event) => {
          if (event.detail.reason === 'closeButton') {
            props.setUserState({
              authenticated: false,
              authState: AuthState.SIGN_IN,
              user: props.userState.user,
              message: null
            })
          }
        }}
        visible={props.passwordRestState.emailVerified}
        closeAriaLabel="Close modal"
        // headers
        header={<Header/>}
      >
        <SpaceBetween size={"m"} direction={"vertical"}>
          <FormField label={<b> Authentication code sent to {props.passwordRestState?.username} </b>}
            // ask user to enter authentication code they receive in email
          >
            <Input
              onChange={({detail}) => {
                resetMessageStatus({userState: props.userState, setUserState: props.setUserState});
                setAuthCode(detail.value);
              }}
              value={authCode} placeholder="Authentication code"/>
          </FormField>

          <FormField label={"New Password"}
            // ask user to enter the new password once
          >
            <Input onChange={({detail}) => {
              resetMessageStatus({userState: props.userState, setUserState: props.setUserState});
              setNewPassword1(detail.value);
            }} value={newPassword1} type="password"  placeholder="New password"/>
          </FormField>

          <FormField label={"Enter New Password Again"}
            // ask user to enter password again and make sure it matches the one entered before.
          >
            <Input onChange={({detail}) => {
              resetMessageStatus({userState: props.userState, setUserState: props.setUserState});
              setNewPassword2(detail.value);
            }} value={newPassword2} onKeyDown={(event) => {
              // press entry key to submit new password request
              if (event.detail.keyCode === 13 && isResetPasswordButtonDisabled()) {
                submitForgetPasswordRequest();
              }
            }} type="password" placeholder="Re-enter new password"/>
          </FormField>

          <NotificationBox userState={props.userState} setUserState={props.setUserState}/>

          <Box textAlign={"center"}>
              <Button variant="primary"
                      disabled={isResetPasswordButtonDisabled()}
                      onClick={() => submitForgetPasswordRequest()}
                      loading={loading}>
                Reset password
              </Button>
          </Box>

          <Alert
            visible={true}
            dismissAriaLabel="Close alert"
            type={"info"}
          >
            Valid passwords must include: at least 8 characters, {specialCharactersPopover()}, a number, an uppercase letter, and a lowercase letter
          </Alert>


        </SpaceBetween>
      </Modal>
    </div>
  );
}