import React from 'react';
import Footer from '@awsscm/argo-core/argo/ui/footer';
import Header from '@awsscm/argo-core/argo/ui/header';
import Navigation from '@awsscm/argo-core/argo/ui/navbar';
import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import MainPage from '../components/MainPage';
import Help from '../components/Tools/Help';
import './App.scss'

export default function App() {
  return (
    <div className="awsui">
      <div id='awsscm-header'>
        <Header />
      </div>
      <div>
        <AppLayout
          tools={<Help />}
          content={<MainPage />}
          navigation={<Navigation isLandingPage={true} navigationMenu={null} />}
          footerSelector='#awsscm-footer'
          headerSelector='#awsscm-header'
          disableBodyScroll={true}
        />
      </div>
      <div id='awsscm-footer'>
        <Footer />
      </div>
    </div>
  );
}

document.body.classList.add('awsui-visual-refresh');
