import * as React from "react";
import { UserState } from "../user/UserState";
import { UsernameValidationPanel } from "./UsernameValidationPanel";
import { NewPasswordSubmitPanel } from "./NewPasswordSubmitPanel";

export interface PasswordResetPanelProps {
  setUserState: (userState: UserState) => void,
  userState: UserState
}

export interface PasswordResetState {
  emailVerified: boolean,
  username: string | null
}

/**
 * React component to control the flow of password reset. Depending on whether the email address is verified or not,
 * it will show different UI component (verify email address, submit new password).
 *
 * @param props component props.
 */
export const PasswordResetPanel = (props: PasswordResetPanelProps) => {
  const [passwordResetState, setPasswordResetState] = React.useState<PasswordResetState>({
    emailVerified: false,
    username: null
  });

  switch (passwordResetState.emailVerified) {
    case true:
      return (
        <div className={"awsscm-argo-password-reset-panel new-pwd-submit"}>
          <NewPasswordSubmitPanel
            setUserState={props.setUserState}
            userState={props.userState}
            passwordRestState={passwordResetState}
            // give user 3 attempts to enter correct authentication code and new password that meets the policies.
            maxAllowedAttempts={3}
          />
        </div>
      );

    case false:
      return (
        <div className={"awsscm-argo-password-reset-panel email-verification"}>
          <UsernameValidationPanel
            setUserState={props.setUserState}
            userState={props.userState}
            passwordRestState={passwordResetState}
            setPasswordRestState={setPasswordResetState}
          />
        </div>
      );
  }
}