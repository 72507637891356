/**
 * User state used in HOC ArgoAuthenticator to manage and decide which UI display.
 */
export interface UserState {
  authenticated: boolean,
  authState: AuthState
  user: any,
  message: NotificationMessage | null
}

/**
 * Enum for different auth states: sign in, multi-factor authentication challenge, password reset.
 */
export enum AuthState {
  SIGN_IN,
  MFA,
  PASSWORD_RESET
}

/**
 * Interface for holding notification message (type could be INFO or ERROR).
 */
export interface NotificationMessage {
  type: NotificationType
  message: string
}

export enum NotificationType {
  ERROR,
  INFO
}
